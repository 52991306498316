<template>
  <LayerPopup :popOpen="params.open" :fixedBtn="true" @popClose="onClose" class="slide timepicker_pop">
    <ul class="pop_list">
      <li class="item reset">
        <button class="label" @click.prevent="onClickReset">{{ $t('commonCode.ZA001') }}</button>
      </li>
      <!-- 활성화 시 클래스 on 추가 -->
      <li class="item" v-for="(info, idx) in times" :key="idx">
        <input type="checkbox"
               :id="`timepicker_check${idx}`"
               :disabled="info.isDisabled"
               v-model="info.isSelected"
               @click="onClickTimes(idx)"
               class="input">
        <label :for="`timepicker_check${idx}`" class="label">{{ info.time }}</label>
      </li>
    </ul>
    <div class="btn_group">
      <button type="button" class="btn btn-ghost" @click.prevent="onClose(false)">{{ $t('commonCode.ZA003') }}</button>
      <button type="button" class="btn btn-basic" :disabled="disabledOk" @click.prevent="onSelected">{{ $t('commonCode.ZA002') }}</button>
    </div>
  </layerpopup>
</template>

<script>

export default {
  id: 'FilterTimePopup',
  components: { LayerPopup: () => import('@/components/base/LayerPopup.vue') },
  props: {
    timeStep: {
      type: Number,
      default: 30,
    },
    startHour: {
      type: Number,
      default: 8,
    },
    startMinute: {
      type: Number,
      default: 0,
    },
    // 몇 시간동안.
    endHour: {
      type: Number,
      default: 0,
    },
    selectedDate: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      default: () => ({
          open: false,
        }),
    },
    disabledTimeIndex: {
      type: Array,
      default: () => [],
    },
    tostpopText: {
      type: String,
    },
    minTimeTick: {
      type: Number,
      default: 0,
    },
    maxTimeTick: {
      type: Number,
      default: 3,
    },
    selectedTimes: {
      type: Array,
      default: () => [],
    },
    checkOnSelect: {
      type: Boolean,
      default: false,
    },
    clearSaveTimes: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      times: [],
      selectTime: [],
      showLimitMsg: true,
      disabledSet: null,
      disabledOk: true,
      onSelect: this.checkOnSelect,
      saveTimes: [],
    };
  },
  computed: {
    clickStep() {
      return this.timeStep === 30 ? 24 : (this.maxTimeTick || 2);
    },
    limit() {
      return 0;
    },
  },
  mounted() {
    this.makeFilterTimes(this.timeStep);
  },
  watch: {
    selectedDate() {
      this.makeFilterTimes(this.timeStep);
    },
    disabledTimeIndex(arr) {
      this.disabledSet = new Set(arr);
      this.makeFilterTimes(this.timeStep);
    },
    params: {
      deep: true,
      handler(params) {
        if (params.open) {
          if (this.disabledTimeIndex?.length && !this.disabledSet) {
            this.disabledSet = new Set(this.disabledTimeIndex);
            this.makeFilterTimes(this.timeStep);
          }

          if (this.clearSaveTimes) {
            this.saveTimes = [];
            this.$parent.clearSaveTimes = false;
            this.$el.querySelector('.pop_list').scrollTo(0, 0);
          }

          if (this.saveTimes.length) {
            const [start, end] = this.saveTimes;
            this.selectTime = [];
            const range = this.range(start, end, 1);
            this.times.forEach((time) => {
              const isSelected = range.includes(time.idx);
              time.isSelected = isSelected;
              // eslint-disable-next-line no-unused-expressions
              isSelected && this.selectTime.push(time.idx);
            });
          } else {
            this.times.forEach((time) => {
              time.isSelected = false;
            });
          }
        }
      },
    },
  },
  methods: {
    range(start, end, step, templateFn, isReverse) {
      let arr = [];
      for (;start <= end; start += step) {
        arr.push(start);
      }
      arr = templateFn ? arr.map(templateFn) : arr;
      return isReverse ? arr.reverse() : arr;
    },
    makeFilterTimes(step) {
      // document.querySelector('.pop_cont').scrollTo(0, 0)
      // getMomentDate(startTime(this.selectedDate, this.startHour, this.startMinute), 'YYYY-MM-DD HH:mm:ss');
      const seldate = `${this.selectedDate} ${this.startHour.toString().padStart(2, '0')}:${this.startMinute.toString().padStart(2, '0')}:00`;
      const date = this.$moment(seldate);
      const times = [];
      let idx = 0;
      let lastHour;
      if (this.endHour) {
        lastHour = (this.startHour + this.endHour).toString();
      } else {
        lastHour = (this.startHour + 12).toString();
      }

      while (date.format('HH') < lastHour) {
        const time = [date.format('HH:mm')];
        date.add(step, 'minute');
        time.push(date.format('HH:mm'));
        times.push({
          idx,
          time: `${time[0]} ~ ${time[1]}`,
          isSelected: false,
          isDisabled: this.disabledSet?.has(idx),
        });
        // eslint-disable-next-line no-plusplus
        idx++;
      }
      this.times = times;

      const { selectedTimes } = this;
      if (selectedTimes.length) {
        const selectedTimesStr = this.selectedTimes.join(', ');
        times.forEach((info) => {
          if (selectedTimesStr.includes(info.time)) {
            info.isSelected = true;
            this.selectTime.push(info.idx);
          }
        });
        this.disabledOk = false;
      }
    },
    onClose(state) {
      this.params.open = state;
      this.selectTime = [];
    },
    onSelected() { // 시간 선택 확인 이벤트
      const start = this.selectTime[0];
      const end = this.selectTime[this.selectTime.length - 1];
      const times = [];
      this.saveTimes = [start, end];

      // eslint-disable-next-line no-plusplus
      for (let i = start; i <= (end || start); i++) {
        times.push(this.times[i]);
      }
      this.$emit('selected', times.map(({ time }) => time));
      this.onClose(false, false);
    },
    // eslint-disable-next-line consistent-return
    onClickTimes(idx) {
      if (this.times[idx].isDisabled) {
        return false;
      }
      document.getElementById(`timepicker_check${idx}`).checked = true;
      this.times[idx].isSelected = true;
      let disabledBtn = false;

      const beforeClickCnt = this.selectTime.length;
      this.selectTime = Array.from((new Set(this.selectTime)).add(idx));

      if (beforeClickCnt === 0) {
        this.showLimitMsg = false;
      } else if (beforeClickCnt >= 1) {
        const first = this.selectTime[0];
        const second = idx;

        if (first > second) {
          this.selectReset(idx);
          // disabledBtn = true
        } else if (this.selectTime[beforeClickCnt - 1] > second) {
          this.selectReset(idx);
          // disabledBtn = true
        } else if (first + this.clickStep < second) { // 제한시간보다 많을 때
          this.selectReset();
          this.showLimitMsg = true;
          disabledBtn = true;
        } else {
          // eslint-disable-next-line no-plusplus
          for (let i = first; i < second; i++) {
            if (this.times[i].isDisabled) {
              this.selectReset();
              this.selectTime.push(idx);
              this.times[idx].isSelected = true;
              break;
            }
            this.times[i].isSelected = true;
          }
        }
      }
        console.log('minTimeTice', this.minTimeTick);
      if (this.minTimeTick) {
        // 2시간 이상, 1시간 단위
        const count = this.times.filter((element) => element.isSelected === true).length;
        this.disabledOk = count < this.minTimeTick || count % 2 !== 0;
      } else {
        this.disabledOk = disabledBtn;
        // console.log('disablebtn else', disabledBtn);
      }
    },
    selectReset(idx) {
      this.selectTime = [];
      this.times.forEach((time) => {
        time.isSelected = false;
      });

      if (typeof idx === 'number') {
        document.getElementById(`timepicker_check${idx}`).checked = true;
        this.times[idx].isSelected = true;
        this.selectTime.push(idx);
      }
    },
    onClickReset() {
      this.selectReset();
      // this.$emit('parentReset');
      this.disabledOk = true;
      this.saveTimes = [];
    },
  },
};
</script>
